import { Route, Routes, Navigate } from "react-router-dom";

import App from "common/app";
import Retrieval from "verify_portal/retrieval";
import RetrievalRequest from "verify_portal/retrieval/request";
import RetrievalDetails from "verify_portal/retrieval/details";

const ROUTES = (
  <Routes>
    <Route path="/" element={<RetrievalRequest />} />
    <Route path="/details" element={<RetrievalDetails />} />
    <Route path="*" element={<Navigate replace to="/" />} />
  </Routes>
);

const APP = (
  <App isAuthenticated={false} mobileSupported>
    <Retrieval>{ROUTES}</Retrieval>
  </App>
);

export default () => APP;
