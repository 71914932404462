import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { NotarialActs } from "graphql_globals";

type Props = {
  act: NotarialActs;
};

function FormattedNotarialAct({ act }: Props) {
  switch (act) {
    case NotarialActs.ACKNOWLEDGEMENT:
      return (
        <FormattedMessage
          id="9747a2d5-cdd0-47c3-891b-55a2cea80504"
          defaultMessage="Acknowledgement"
        />
      );
    case NotarialActs.COPY_CERTIFICATION:
      return (
        <FormattedMessage
          id="78138ad9-b3ef-45d1-a01a-f5b6edc0dc84"
          defaultMessage="Copy Certification"
        />
      );
    case NotarialActs.VERIFICATION_OF_FACT:
    case NotarialActs.VERIFICATION_OF_FACT_PS1583:
      return (
        <FormattedMessage
          id="6203988f-94fa-4f90-8176-f9f6e9fbba69"
          defaultMessage="Verification of Fact"
        />
      );
    case NotarialActs.OATH_AFFIRMATION:
      return (
        <FormattedMessage
          id="311850ce-a199-4d0c-95ce-60476cdce4a5"
          defaultMessage="Oath/Affirmation"
        />
      );
    case NotarialActs.JURAT:
      return <FormattedMessage id="a88a1302-ac2e-4a5b-8a30-d7797711d05c" defaultMessage="Jurat" />;
    case NotarialActs.AFFIDAVIT:
      return (
        <FormattedMessage id="1ba72ce6-d62d-43c7-8da5-4b0de737e4e1" defaultMessage="Affidavit" />
      );

    default:
      return <>{act}</>;
  }
}

export default memo(FormattedNotarialAct);
