import "./index.scss";

import type { ReactNode } from "react";

import RetrievalHeader from "./header";
import RetrievalFooter from "./footer";

type Props = {
  children?: ReactNode;
};

function Retrieval({ children }: Props) {
  return (
    <div className="Retrieval">
      <RetrievalHeader />
      {children}
      <RetrievalFooter />
    </div>
  );
}

export default Retrieval;
