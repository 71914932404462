import { memo } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";

type Props = {
  onRetry: () => void;
};

function RetrievalDetailsFailureModal(props: Props) {
  const { onRetry } = props;

  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="f04954b5-fa68-4186-8112-210da4da4f25"
          defaultMessage="No documents found"
        />
      }
      isSensitive={false}
      buttons={[
        <Button
          key="dismiss"
          automationId="dismiss-modal-button"
          onClick={onRetry}
          buttonColor="action"
          variant="tertiary"
        >
          <FormattedMessage id="f8a20a1b-9e1d-4327-8127-76b761d84e48" defaultMessage="Retry" />
        </Button>,
      ]}
      footerSeparator={false}
    >
      <FormattedMessage
        id="ebae13bc-8c38-4671-8bf1-8e08c8df66ac"
        defaultMessage="Unable to find documents with the provided details."
      />
    </WorkflowModal>
  );
}

export default memo(RetrievalDetailsFailureModal);
