import ActionsSection from "./actions_section";
import FeaturesSection from "./features_section";
import Styles from "./index.module.scss";

export default function Footer() {
  return (
    <div className={Styles.verifyFooter}>
      <div className={Styles.container}>
        <ActionsSection />
        <FeaturesSection />
      </div>
    </div>
  );
}
