import { FormattedMessage } from "react-intl";

import { useMobileScreenClass } from "common/core/responsive";
import Link from "common/core/link";
import { PROOF_CAPABILITIES_URL, BUSINESS_PRICING_URL } from "constants/marketing";
import { Heading, Paragraph } from "common/core/typography";
import { ButtonStyledLink } from "common/core/button/button_styled_link";

import Styles from "./actions_section.module.scss";

export default function ActionsSection() {
  const useCompactUi = useMobileScreenClass();
  const info = (
    <>
      <Heading level="h2" textStyle="headingSix" className={Styles.header}>
        <FormattedMessage
          id="c8efe052-c012-4a2a-b22f-352b86d6150e"
          defaultMessage="Secure your business transactions with Proof"
        />
      </Heading>
      <Paragraph textColor="subtle" className={Styles.info}>
        <FormattedMessage
          id="4c3921c8-5f9f-4bcc-9dbd-5dab3abd2f62"
          defaultMessage="Proof protects your most important transactions with built-in fraud detection and one of the highest levels of identity assurance available."
        />
      </Paragraph>
    </>
  );
  const links = (
    <Link className={Styles.link} href={PROOF_CAPABILITIES_URL}>
      <FormattedMessage id="bb86f8fd-9501-4654-933f-1d86b00bce26" defaultMessage="Learn more" />
    </Link>
  );

  const getStartedButton = (
    <ButtonStyledLink
      href={BUSINESS_PRICING_URL}
      className={Styles.getStartedButton}
      buttonSize="large"
      buttonColor="action"
      variant="primary"
    >
      <FormattedMessage
        id="6931a161-b582-4619-863f-2236b551f706"
        defaultMessage="Get started free"
      />
    </ButtonStyledLink>
  );

  if (useCompactUi) {
    return (
      <div className={Styles.actions}>
        {info}
        {getStartedButton}
        {links}
      </div>
    );
  }

  return (
    <div className={Styles.actions}>
      <div>
        {info}
        {links}
      </div>
      {getStartedButton}
    </div>
  );
}
