import "./index.scss";

import { memo } from "react";
import { FormattedMessage } from "react-intl";
import { useSearchParams, useNavigate } from "react-router-dom";

import { Heading } from "common/core/typography";

import RetrievalRequestForm from "./form";

export type HandleSubmitArgs = {
  retrievalId: string;
  retrievalPin: string;
};

function RetrievalRequest() {
  const navigate = useNavigate();
  const [queryArgs] = useSearchParams();
  const signerFirstName = queryArgs.get("signer_first_name");
  const retrievalId = queryArgs.get("retrieval_id");

  const handleSubmit = ({ retrievalId, retrievalPin }: HandleSubmitArgs) => {
    navigate(`/details?retrievalId=${retrievalId}`, { state: { retrievalPin } });
  };

  return (
    <div className="RetrievalRequest">
      <div className="RetrievalRequest--main">
        <Heading
          data-automation-id="retrieval-request-title"
          className="RetrievalRequest--title"
          level="h1"
          textStyle="headingFour"
          textAlign="center"
        >
          <FormattedMessage
            id="b3b14b72-1512-4a31-ba95-b6ef1d7971f6"
            defaultMessage="{name, select, null{Access completed documents} other{Access {name}'s completed documents}}"
            values={{ name: signerFirstName }}
          />
        </Heading>

        <RetrievalRequestForm onRetrievalSubmit={handleSubmit} retrievalId={retrievalId} />
      </div>
    </div>
  );
}

export default memo(RetrievalRequest);
