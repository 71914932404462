import { FormattedMessage } from "react-intl";

import Padlock from "assets/images/marketing/padlock.svg";
import Sign from "assets/images/marketing/sign.svg";
import Headphone from "assets/images/marketing/headphone.svg";
import Encrypting from "assets/images/marketing/encrypting.svg";
import { Heading, Paragraph } from "common/core/typography";

import Styles from "./features_section.module.scss";

export default function FeaturesSection() {
  return (
    <div className={Styles.features}>
      <div className={Styles.feature}>
        <img className={Styles.image} src={Padlock} alt="" />
        <Heading level="h3" textStyle="headingSix" className={Styles.header}>
          <FormattedMessage
            id="c271e694-2dd7-41ff-8110-6fa184e5ebd1"
            defaultMessage="100% secure"
          />
        </Heading>
        <Paragraph textColor="subtle">
          <FormattedMessage
            id="25274a0f-bd5e-4b6d-8d48-020ec6aa109f"
            defaultMessage="Access ID forensic analysis and full audit trails"
          />
        </Paragraph>
      </div>

      <div className={Styles.feature}>
        <img className={Styles.image} src={Sign} alt="" />
        <Heading level="h3" textStyle="headingSix" className={Styles.header}>
          <FormattedMessage
            id="b2e8f2f8-d177-42be-8f23-6bc729c76330"
            defaultMessage="Elevated eSign"
          />
        </Heading>
        <Paragraph textColor="subtle">
          <FormattedMessage
            id="a28dd5da-b9ce-4bfb-854e-c5eaaffaac84"
            defaultMessage="Every eSign is backed by bank-grade security"
          />
        </Paragraph>
      </div>

      <div className={Styles.feature}>
        <img className={Styles.image} src={Headphone} alt="" />
        <Heading level="h3" textStyle="headingSix" className={Styles.header}>
          <FormattedMessage
            id="78d12d5e-08e0-4cd1-ae75-2a7e71c1f889"
            defaultMessage="On-demand identity agents"
          />
        </Heading>
        <Paragraph textColor="subtle">
          <FormattedMessage
            id="46c28012-9172-4f3d-bdf9-d4f5af6a478c"
            defaultMessage="Notaries and trusted referees available to signers 24/7"
          />
        </Paragraph>
      </div>

      <div className={Styles.feature}>
        <img className={Styles.image} src={Encrypting} alt="" />
        <Heading level="h3" textStyle="headingSix" className={Styles.header}>
          <FormattedMessage
            id="2ba48170-66f1-4151-9c21-ee5592ae29f2"
            defaultMessage="Developer-free automation"
          />
        </Heading>
        <Paragraph textColor="subtle">
          <FormattedMessage
            id="0eaeeb88-5ce2-4464-abaa-70e5620a224a"
            defaultMessage="Our integration tools get you started in minutes"
          />
        </Paragraph>
      </div>
    </div>
  );
}
